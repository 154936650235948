const [popupAgree, popupDisagree] = Array.from(
	document.querySelectorAll('.popup__btn')
) as HTMLButtonElement[]
const popup = document.querySelector('.popup__shadow') as HTMLElement
const popupError = document.querySelector('.popup__msg') as HTMLParagraphElement

const checkPopup = (): void => {
	if (window.getComputedStyle(popup).getPropertyValue('display') === 'block') {
		document.body.classList.add('sticky-body')
	}
}

const closePopup = (): void => {
	popup.style.display = 'none'
	document.body.classList.remove('sticky-body')
}

checkPopup()
popupAgree.addEventListener('click', closePopup)
popupDisagree.addEventListener(
	'click',
	() => (popupError.style.display = 'block')
)
